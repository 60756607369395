.App {
  text-align: center;
  background-color: #334A73;
  color: white
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: '#0F0F0F';
}

.App-link {
  color: #61dafb;
}

body{
  background-color: #f1f1f1;
}

button {
  background: none;
  border: none;
  padding: 0;
}

.tab-content{
  background-color: #f1f1f1;
  margin-left: 290px;
}

.events_wrapper{
  position: relative;
  display: block;
  width: 100%;
}

.img-fluid {
  width: 450px;
  height: 250px;
  object-fit: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav{
  position: fixed;
  width: 275px;
  height: 100%;
  padding-top: 20px;
  z-index: 1;
}

.nav-link{
  margin: 0 20px;
  border: 1px solid #004ab7;
  border-radius: 10px;
}

.nav-link-divider {
  border-top: 3px solid #bbb;
}

.nav-link-event-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0px 10px 20px 10px;
  color: white;
  text-align: center;
}

.event_wrapper{
  width: 100%;
  position: relative;
  display: block;
  padding-right: 25px;
}

.events{
  cursor: pointer;
}


.archives{
  cursor: pointer;
}


.qrcode_wrapper{
  position: relative;
  float: right;
  padding-right: 60px;
  padding-top: 50px;
}


.eventtopwrapper{
  width: 100%;
  height: 300px;
  position: relative;
  display: block;
}

.eventbottomwrapper{
  padding:50px;
}

.qrCode{
  position: absolute;
  top: 0;
  left: 0;

}

.qrcode_button{
  position: absolute;
  top: 280px;
  right: 60px;
  background-color: #0d6efd;
  color: #fff;
  width: 200px;
  padding: 10px 10px;
  border: 1px solid #004ab7;
  border-radius: 10px;
}

.button{
  background-color: #0d6efd;
  color: #fff;
  width: 200px;
  padding: 10px 10px;
  border: 1px solid #004ab7;
  border-radius: 10px;
  cursor: pointer;
}

.searchbutton {
  background-color: #0d6efd;
  color: #fff;
  width: 200px;
  padding: 10px 30px;
  border: 1px solid #004ab7;
  border-radius: 10px;
  margin-left: 15px;
  margin-bottom: 25px;
}

.button-disabled {
  background-color: lightgray;
}

table tr td{
  padding: 15px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

.mtop25 > td {
  vertical-align: middle;
  text-align: left;
  min-width: 150px;
}

.mtop25 > th {
  vertical-align: middle;
  text-align: left;
  min-width: 150px;
}

td, th {
  vertical-align: middle;
  text-align: left;
  /* min-width: 150px; */
}
/*
.thNarrow {
  width: 200px !important;
} */

.event-registrations tr > *:nth-child(1) {
  min-width: 105px;
}
.event-registrations tr > *:nth-child(2) {
  min-width: 117px;
}
.event-registrations tr > *:nth-child(3) {
  min-width: 200px;
}
.event-registrations tr > *:nth-child(4) {
  min-width: 200px;
}
.event-registrations tr > *:nth-child(5) {
  display: 60px
}
.event-registrations tr > *:nth-child(6) {
  min-width: 60px;
}
.event-registrations tr > *:nth-child(7) {
  min-width: 60px;
}
.event-registrations tr > *:nth-child(8) {
  min-width: 160px;
}
.event-registrations tr > *:nth-child(9) {
  min-width: 160px;
}
.event-registrations tr > *:nth-child(10) {
  min-width: 110px;
}
.event-registrations tr > *:nth-child(11) {
  min-width: 110px;
}
.event-registrations tr > *:nth-child(12) {
  min-width: 105px;
}

.new-registration tr > *:nth-child(1) {
  min-width: 65px;
}
.new-registration tr > *:nth-child(2) {
  min-width: 117px;
}
.new-registration tr > *:nth-child(3) {
  min-width: 200px;
}
.new-registration tr > *:nth-child(4) {
  min-width: 200px;
}
.new-registration tr > *:nth-child(5) {
  display: 60px
}
.new-registration tr > *:nth-child(6) {
  min-width: 100px;
}
.new-registration tr > *:nth-child(7) {
  min-width: 115px;
}

.new-registration-upper-div {
  display: flex;
  flex-direction: column;
}

.new-registration-upper-div-upper-row {
  display: flex;
  justify-content: space-between;
}

.new-registration-upper-div-lower-row {
  display: flex;
}

.inputSearch {
  height: 38px;
}

.syncButton {
  width: 350px;
}

.onsite tr > *:nth-child(1) {
  min-width: 105px;
}
.onsite tr > *:nth-child(2) {
  min-width: 200px;
}
.onsite tr > *:nth-child(3) {
  min-width: 200px;
}
.onsite tr > *:nth-child(4) {
  display: 65px;
}
.onsite tr > *:nth-child(5) {
  display: 65px;
}
.onsite tr > *:nth-child(6) {
  display: 100px;
}
.onsite tr > *:nth-child(7) {
  min-width: 100px;
}
.onsite tr > *:nth-child(8) {
  display: 60px;
}

.wrapper{
  width: 100%;
    position: relative;
    display: block;
    margin-top: 25px;
    margin-left: 25px;
}

.mtop25{
  margin-top: 25px;
}

.card-text{
  text-align: justify;
}

.card-body{
  padding-right: 20px;
}

.event_image_wrapper{
  position: relative;
  float: left;
  padding-top: 25px;
  padding-left: 50px;
}

.event_image_wrapper img{
  width: 333px;
  height: 200px;
}

#send{
  background-image: url("../public/user-follow.svg");
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}

#download{
  background-image: url("../public/arrow-down-circle.svg");
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}

.event_title{
  position: relative;
  display: inline;
  float: left;
}

.imgwrapper{
  width: 120px;
}

.imgwrapper .img-fluid{
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
}

.imgwrapper > img {
  width: 100px;
  height: 100px;
  border-radius: 50% !important;

}

#register{
  background-image: url("../public/register.svg");
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin-left: 15px;
}

#login{
  background-image: url("../public/login2.svg");
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin-left: 15px;
}

.iconSyncButton {
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin: 0 0 0 6px;
 }

#cancel{
  background-image: url("../public/cancel.svg");
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin-left: 15px;
}

#show{
  background-image: url("../public/login2.svg");
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  color: black;
  background-size: cover;
  margin-left: 15px;
  position: relative;
  top: 3px;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  color: gray;
  width: 500px;
  background-color: whitesmoke;
  padding: 0 20px;
}

.modal-header, .modal-footer {
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 20px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.modal-body-error {
  background-color: #ffb3b3;
}

.modal-body-successful {
  background-color: #b3ffb3;
}

.modal-close-button {
  border: 1px solid whitesmoke;
  border-radius: 8px;
  padding: 8px;
}

.modal-close-button:hover {
  background-color: white;
}

.end-aligned-text {
  text-align: end;
}

.top-aligned-text {
  vertical-align: top;
}

.minimal-width {
  width: 0;
}

.maximal-width {
  width: 100%;
}