.app-page-header {
  display: flex;
  flex: 1;
  background-image: url(https://mvuk.hu/wp-content/uploads/2022/01/neonbrand-3GZNPBLImWc-unsplash-scaled.jpg);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;

  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 150px 0px 150px 0px;
}
.app-page-header-overlay {
  background-color: transparent;
  background-image: linear-gradient(270deg, #FFFFFF1A 36%, #D8D8D8 84%);
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.content-container {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 100px 0px 100px 0px;
    color: #7A7A7A;
    max-width: 800px;
}
.content-container a {
  color: #c36;
  text-decoration: none;
}

.content-image {
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

@media (max-width: 1024px) {
  .app-page-header {
      padding: 150px 30px 150px 30px;
  }
  .content-container {
      padding: 100px 30px 100px 30px;
  }
}
@media (max-width: 767px) {
  .app-page-header {
      padding: 150px 20px 75px 20px;
  }
  .elcontent-container {
      padding: 100px 20px 100px 20px;
  }
}
