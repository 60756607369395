button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.upperRowDiv {
    display: flex;
    flex-direction: column;
}

.mainRowDiv {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden
}

.leftPanelDiv {
    display: flex;
    flex-direction: column;
}

.rightPanelDiv {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 30px;
    flex: 1;
    width: calc(100vw - 30px - 231px - 275px - 2rem);
}

.seatOrderContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.isCardSelected {
    box-shadow: 0 0 0 4px gray;
}

.participantsContainer {
    display: flex;
    gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
}

.generateButtonDiv {
    height: 10%;
}

.upperRowContainer {
}

.loadingLine {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, #3498db 0%, #3498db 50%, transparent 50%);
    background-size: 200% 100%;
    animation: loading-line 2s linear infinite;
}

@keyframes loading-line {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

.roundTabsContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.propertiesContainerWrapper {
    display: flex;
    justify-content: flex-end;
}

.propertiesContainer {
    display: flex;
    gap: 30px;
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border-color: rgb(51, 74, 115);
    border-width: 1px;
    border-style: solid;
    background-color: rgb(221, 225, 234);

    .propertyInputGroup {
        display: flex;
        flex-direction: column;
        gap: 8px;

        input[type="checkbox"] {
            display: block;
            margin-right: auto;
        }

        .saveButton {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0 auto;

            button {
                margin: 0;
                background-color: rgb(51, 74, 115);
                color: #fff;
                width: 200px;
                padding: 10px 30px;
                border-radius: 10px;
            }

            button:disabled {
                background-color: lightgray;
                border-color: lightgrey;
                color: darkgray;
                cursor: unset;
            }
        }
    }
}

.roundTabsContainer > ul {
    width: 60%;
    display: flex;
}

.roundTab {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 16%;
    min-height: 60px;
    font-weight: 500;
    font-size: 20px;
    color: white;
    border-radius: 8px;
    background-color: rgb(51, 74, 115);
}

.roundTab:hover {
    color: lightgray;
    background-color: #2e4266;
}

.roundTabSmall {
    font-weight: normal;
    font-size: 13px;
    min-height: 20px;
    background-color: rgb(188, 204, 232);
}

.roundTabSmall:hover {
    color: lightgray;
    background-color: rgb(103, 127, 168);
}

.disabledButton {
    background-color: lightgray !important;
    color: darkgray !important;
    cursor: unset !important;
}

.disabledButton button {
    cursor: unset !important;
}

.roundTabSelected {
    background-color: #ffffff !important;
    color: rgb(51, 74, 115) !important;
}

.roundContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
}

.roundButton {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    font-weight: 500;
    font-size: 20px;
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgb(51, 74, 115);
    cursor: pointer;
}

.tablesContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    overflow: auto;
    padding: 20px;
}

.table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* width: 33%; */
    gap: 8px;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0 10px 10px;
    background-color: #e9ecef;
}

.tableName {
    font-size: 41px;
    font-weight: bold;
    text-align: center;
    align-self: center;
    aspect-ratio: 1;
}

.tableConflict {
    background-color: rgba(250, 128, 114, 0.616);
}

.emptyTableSeat {
    height: 80px;
}

.tableCard {
    position: relative;
    padding: 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 200px;
    background-color: white;
    gap: 8px;
    border-radius: 8px;
}

.tableCard:hover {
    box-shadow: 0 0 0 4px gray;
}

.cardLeftColumn {
    display: flex;
    flex-direction: column;
    min-width: 10%;
}

.cardCenterColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    min-height: 40px;

    .cardTitle {
        font-weight: 600;
        font-size: 16px;
        color: black;
        margin: 0 0 0 8px;
    }

    .cardTagWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 90%;

        .cardTag {
            display: flex;
            justify-content: center;
            border: 1px solid rgb(102, 102, 102);
            border-radius: 4px;
            background-color: rgba(255, 255, 255, .7);
            font-weight: 300;
            font-size: 12px;
            color: black;
            margin: 0 0 0 8px;
            min-width: 15px;
        }
    }
}

.cardUpperRow {
    display: flex;
    flex-direction: row;
    height: 85%;
}

.cardLowerRow {
    height: 15%;
    display: flex;
    justify-content: center;
    color: gray;
}

.indicator {
    border: 1px solid whitesmoke;
    min-height: 21px;
    width: 11%;
    color: gray;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.seatIdDiv {
    margin: 0 0 0 6px;
    width: 25px;
    height: 25px;
    font-weight: 600;
    font-size: 20px;
    color: gray;
}

.removeIconDiv {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: flex-end;
    justify-items: center;
    margin: 0 auto;
}

.removeIconDiv > img {
    width: 25px;
    height: 25px;
}

.addingNewTableDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 8px;
    margin-left: 20px;
}

.iconAddingNew {
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
}

.iconMinus {
    width: 25px;
    height: 15px;
}

.tableSeat {
    min-height: 80px;
}


.tableSeat:hover {
    /* box-shadow: 0 0 0 4px gray; */
}

.participantIsSelected {
    background-color: #90cc90c5;
}

.ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ul > li {
    margin: 8px 8px 16px 8px;
}

.button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
}

.buttonAttention {
    background-color: rgba(250, 128, 114, 0.616);
}

.buttonWithTooltip {
    position: relative;
    height: 50px;
}

.errorDiv {
    color: red;
    font-weight: bold;
    padding: 20px;
    background-color: lightcoral;
}

.tooltipText {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 100px;
    color: white;
    font-size: 14px;
    background-color: #acacace1;
    border-radius: 16px;
    padding: 10px 15px 10px 15px;
}

.hoverText:hover .tooltipText {
    visibility: visible;
}

.buttonOnModal {
    width: 50%;
    border-radius: 8px;
    padding: 8px 20px;
}

.buttonOnModal:hover {
    background-color: lightblue;
}

.buttonDelete {
    background-color: lightcoral;
    color: white;
}
